const redirectToNest = (nestId: string) => () => {
  window.location.href = `https://fulfillment-${nestId}-production.platform.flyzipline.com/`
  return null
}

export const Rw1Linkview = redirectToNest('rw1')
export const Rw2LinkView = redirectToNest('rw2')
export const Gh1LinkView = redirectToNest('gh1')
export const Gh2LinkView = redirectToNest('gh2')
export const Gh3LinkView = redirectToNest('gh3')
export const Gh4LinkView = redirectToNest('gh4')
export const Gh5LinkView = redirectToNest('gh5')
export const Gh6LinkView = redirectToNest('gh6')
export const Ke1LinkView = redirectToNest('ke1')
export const Ci1LinkView = redirectToNest('ci1')
export const Ngcr1LinkView = redirectToNest('ngcr1')
export const Ngby1LinkView = redirectToNest('ngby1')
export const Ngkd1LinkView = redirectToNest('ngkd1')
export const Ua1LinkView = redirectToNest('ua1')
export const Ua2LinkView = redirectToNest('ua2')
export const Ua3LinkView = redirectToNest('ua3')
