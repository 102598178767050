import { DeliveryConfirmationCode } from 'src/models/delivery'
import {
  CancelationReason,
  CreditCheckOverrideReason,
  OffNominalReason,
  Order,
  OrderMethod,
  OrderPreview,
  OrderStatusDetails,
  OrderValidation,
  Priority,
  PriorityReason,
} from 'src/models/order'
import { PrinterLabel } from 'src/models/printer_label'
import { ScriptCollection } from 'src/models/script'
import {
  transformDeliveryConfirmationCode,
  transformOrder,
  transformOrderPreview,
  transformOrderStatusDetails,
  transformRequestedProduct,
} from 'src/utils/transformers'

import client from './client'

export async function getOrderMethods(): Promise<OrderMethod[]> {
  const response = await client.get('/api/order_methods/')
  return response?.data
}

export async function getCancelationReasons(): Promise<CancelationReason[]> {
  const response = await client.get('/api/internal/cancelation_reasons/')

  // transform the order response into the app's object
  const cancelationReasons = response.data
  return cancelationReasons.map(
    (reason: { short_name: string; reason: string }) => ({
      label: reason.short_name,
      value: reason.short_name,
    })
  )
}

interface PriorityReasonResponse {
  priority: number
  reason: string
  short_name: string
  is_active: boolean
}

export async function getPriorityReasons(): Promise<PriorityReason[]> {
  const response = await client.get('/api/internal/priority_reasons/')

  const priorityReasons = response.data

  return priorityReasons.reduce(
    (acc: PriorityReason[], value: PriorityReasonResponse) => {
      if (value.is_active) {
        acc.push({
          value: value.short_name,
          priority: value.priority,
          label: value.reason,
        })
      }
      return acc
    },
    []
  )
}

export async function getOffNominalReasons(): Promise<OffNominalReason[]> {
  const response = await client.get('/api/internal/off_nominal_reasons/')

  // transform the order response into the app's object
  const offNominalReasons = response.data
  return offNominalReasons.map(
    (reason: { id: number; short_name: string; reason: string }) => ({
      id: reason.id,
      label: reason.reason,
      value: reason.short_name,
    })
  )
}

/**
 * Retrieves Payers, which are used in the BillableTo field of the Order
 * creation page.
 */
export async function getPayers(): Promise<string[]> {
  const response = await client.get('/api/internal/payers')
  return response?.data
}

export async function getOrders({ needsSignature = false } = {}): Promise<
  OrderPreview[]
> {
  const url = needsSignature
    ? '/api/internal/orders/?needs_signature=1'
    : '/api/internal/orders/'
  const response = await client.get(url)

  // transform the order response into the app's object
  return response?.data.map(transformOrderPreview)
}

export async function getOrder(id: string): Promise<Order> {
  const response = await client.get(`/api/internal/orders/${id}/`)
  return transformOrder(response.data)
}

export async function cancelOrder(id: string, reason: string): Promise<Order> {
  const response = await client.delete(`/api/internal/orders/${id}/`, {
    params: {
      reason,
    },
  })
  return transformOrder(response.data)
}

export async function createOrder(order: Order): Promise<Order> {
  const requestedProducts = order.requestedProducts.map((request) => ({
    priority: request.priority,
    product: request.product,
    attributes: request.attributes,
    packaged: request.packaged,
    quantity_confirmed: request.quantityConfirmed,
    quantity_pending: request.quantityPending,
    quantity_canceled: request.quantityCanceled,
  }))

  const payload = {
    order_method: order.orderMethod,
    orderer: order.orderer.username,
    priority: order.priority,
    priority_reason: order.priorityReasons,
    time_scheduled: order.timeScheduled,
    delivery_window_start: order.deliveryWindowStart,
    delivery_window_end: order.deliveryWindowEnd,
    requested_products: requestedProducts,
    comments: order.comments,
    delivery_site: order.deliverySite,
    pharmacist_name: order.pharmacistName,
    pharmacist_id: order.pharmacistId,
    recipient: order.recipient
      ? {
          name: order.recipient.name,
          phone_number: order.recipient.phoneNumber,
        }
      : undefined,
    blood_uses_per_unit: order.bloodUsesPerUnit,
    billable_to: order.billableTo,
  }

  const response = await client.post('/api/orders/', payload)
  // TODO: Once the order serializer for get and create request is merged,
  // transform the order from the response (instead of returning the original order)
  return {
    ...order,
    // Override the ID with the new order.
    id: response.data.id,
    requestedProducts: response.data.requested_products.map(
      transformRequestedProduct
    ),
  }
}

export async function updateOrder(order: Order): Promise<Order> {
  // Only a subset of fields can be modified, so serialize only those.
  const priority = order.priority
  const serialized = {
    // flatten requested products
    requested_products: order.requestedProducts.map((request) => ({
      id: request.id,
      attributes: request.attributes,
      cancelation: request.cancelation,
      num_fulfilling_units: request.numFulfillingUnits,
      packages: request.packaged,
      product: request.product,
      quantity_confirmed: request.quantityConfirmed,
      quantity_pending: request.quantityPending,
      quantity_canceled: request.quantityCanceled,
      time_created: request.timeCreated,
      priority, // overwrite the priority if updated.
    })),
    comments: order.comments,
    off_nominal_reason: order.offNominalReason,
    delivery_site: order.deliverySite,
    pharmacist_name: order.pharmacistName,
    pharmacist_id: order.pharmacistId,
    recipient: order.recipient?.name
      ? {
          name: order.recipient.name,
          phone_number: order.recipient?.phoneNumber ?? '',
        }
      : undefined,
    priority: order.priority,
    priority_reason: order.priorityReasons ?? [],
    billable_to: order.billableTo,

    // Timestamps
    time_payment_received:
      (order.requiresPayment && order.timePaymentReceived) || null,
    time_scheduled:
      order.priority === Priority.SCHEDULED ? order.timeScheduled : null,
    delivery_window_start:
      order.priority === Priority.CUSTOM_WINDOW
        ? order.deliveryWindowStart
        : null,
    delivery_window_end:
      order.priority === Priority.CUSTOM_WINDOW
        ? order.deliveryWindowEnd
        : null,
    time_customer_notified_order_confirmed: order.timeCustomerNotifiedConfirmed,
    time_customer_notified_order_out_of_stock:
      order.timeCustomerNotifiedOutOfStock,
    time_customer_notified_credit_check_failed:
      order.timeCustomerNotifiedCreditCheckFailed,

    // Credit check data
    credit_check_override_reason: order.creditCheckOverrideReason,
    facility_credit_check_status: order.facilityCreditCheckStatus,
  }
  const response = await client.patch(
    `/api/internal/orders/${order.id}/`,
    serialized
  )
  return transformOrder(response.data)
}

export async function validateOrder(id: string): Promise<{
  validations: OrderValidation[]
  errors: { [key: string]: any }
  extra: { [key: string]: any }
}> {
  const response = await client.get(`/api/validate/order/${id}/`)
  return response.data
}

export async function scheduleShipments(
  orderId: string,
  shipmentsMap: Record<string, Record<string, string>>
) {
  const response = await client.patch(
    `/api/internal/schedule_shipments/${orderId}/`,
    shipmentsMap
  )
  return response.data
}

export async function updateOrderApproval(
  orderId: string,
  approver: string
): Promise<{ orderId: string; approver: string }> {
  const update = {
    order: orderId,
    approver,
  }

  const response = await client.post(
    '/api/customer/rbc/ncbt_order_approval/',
    update
  )
  return response.data
}

export async function removeOrderApproval(orderId: string): Promise<void> {
  await client.delete(`/api/customer/rbc/ncbt_order_approval/${orderId}/`)
}

export async function getOrderStatusDetails(
  orderId: string
): Promise<OrderStatusDetails> {
  const response = await client.get(`/api/order_statuses/${orderId}/`)
  return transformOrderStatusDetails(response.data)
}

interface CreditCheckOverrideReasonPayload {
  id: number
  reason: string
  short_name: string
}

export async function getCreditCheckOverrideReasons(): Promise<
  CreditCheckOverrideReason[]
> {
  const response = await client.get(
    '/api/internal/credit_check_override_reasons/'
  )
  return response.data.map((value: CreditCheckOverrideReasonPayload) => ({
    id: value.id,
    value: value.short_name,
    label: value.reason,
  }))
}

export async function getScriptsForOrder(
  orderId: string
): Promise<Record<string, ScriptCollection>> {
  const response = await client.get(`/api/customerscript/orders/${orderId}/`)
  return response.data
}

export async function getSafetyScript(): Promise<ScriptCollection> {
  const response = await client.get('/api/customerscript/orders/safety_check/')
  return response.data
}

export async function regenerateDeliveryConfirmationCode(
  orderId: string
): Promise<DeliveryConfirmationCode> {
  const response = await client.post(`/api/delivery/code/order/${orderId}/`)
  return transformDeliveryConfirmationCode(response.data)
}

export async function getConfirmationCodeLabel(
  orderId: string
): Promise<PrinterLabel> {
  const response = await client.get(
    `api/printerlabel/confirmation_code/${orderId}/`
  )
  return response.data
}

export async function manuallyConfirmDelivery(
  orderId: string,
  phone_number: string
): Promise<DeliveryConfirmationCode> {
  const response = await client.post(
    `/api/delivery/code/order/${orderId}/confirmation/`,
    { phone_number }
  )
  return transformDeliveryConfirmationCode(response.data)
}

export async function getModeSlottingToolReportUrl(): Promise<string> {
  const response = await client.get(
    'api/internal/orders/slotting_tool_report_url'
  )
  return response.data
}

export async function saveProductUsage(
  usage: string,
  sku: string,
  category: string
): Promise<void> {
  const response = await client.patch(
    '/api/internal/orders/save_product_usage/',
    { usage, category }
  )
  return response.data
}
