import axios from 'axios'
import { AxiosError } from 'axios'
import localForage from 'localforage'
import omit from 'lodash/omit'
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { toast } from 'react-toastify'

import { DeliverySite } from 'src/models/sites'
import PollService from 'src/services/PollService'
import ViewStore from 'src/stores/ViewStore'
import env from 'src/utils/environment'
import { delay } from 'src/utils/promises'

enum LocalState {
  CREATED = 1,
  DIRTY = 2,
  DELETED = 3,
}

type IdType = string | number

type WithId<T> = {
  id: T
  directDeliverySiteId?: number
  deliverySites?: DeliverySite[]
}

export type SyncErrors = Record<string, any>

type Cacheable<T> = T & {
  // if there are changes stored client-side only that must be synced to the server
  _localState?: LocalState
  // save any errors when attempting to sync to the server
  _syncErrors?: SyncErrors
  // when was this last accessed? Timestamp in Unix epoch time
  _lastAccessed?: number
}

interface PollConfig {
  name: string
  interval?: number
}

// How long in ms to keep entries in the detailedEntities cache.
// For now, keep entities for 1 day.
const EXPIRATION_MS = 1000 * 60 * 60 * 24

/**
 * Abstract, base store that handles caching and syncing of data locally.
 * This store supports keeping both a list and a detailed selected entity.
 */
export default abstract class LocalCacheStore<
  Entity extends WithId<IdType>,
  DetailedEntity extends Entity,
> {
  @observable entityList: Cacheable<Entity>[] = []
  @observable detailedEntities: Record<IdType, Cacheable<DetailedEntity>> = {}
  @observable loadingEntities = true
  @observable selectedEntityId?: Entity['id']

  pollService: PollService<string, Entity[]> = new PollService()

  constructor() {
    makeObservable(this)

    // Persist properties locally, using localForage (IndexedDB by default).
    // This call will also hydrate the store with locally persisted data.
    makePersistable(
      this,
      {
        name: this.constructor.name,
        properties: this.properties,
        storage: localForage,
        stringify: false, // IndexedDB supports storage without serializing as strings.
        debugMode: env.ENVIRONMENT === 'development',
      },
      { delay: 200, fireImmediately: false }
    ).then(() =>
      // Purge old entities to reduce memory footprint of store.
      // For simplicity, we only do this on hydrate (i.e. hard page reloads)
      // because we expect it to happen at least once a day when a user logs in.
      this._purgeLru()
    )

    // Sync whenever the local state changes.
    autorun(() => this._syncLocalEntities())
  }

  /* Override the following methods */

  /** How often to poll for updates for the list. */
  get pollConfig(): PollConfig | undefined {
    return undefined
  }

  /** List of properties to persist locally. */
  get properties(): (keyof this)[] {
    return ['entityList', 'detailedEntities']
  }

  /** Makes a GET request to fetch an updated list. */
  protected abstract refreshEntityList(): Promise<Entity[]>

  /** Makes a GET request to fetch a single entity by id. */
  protected abstract getEntity(entityId: Entity['id']): Promise<DetailedEntity>

  /** Makes a POST request to create a new entity. */
  protected abstract createEntity(
    entity: DetailedEntity
  ): Promise<DetailedEntity>

  /** Makes a PUT request to update an existing entity. */
  protected abstract updateEntity(
    entity: DetailedEntity
  ): Promise<DetailedEntity>

  /** Makes a DELETE request to remove an existing entity. */
  protected abstract deleteEntity(
    entity: DetailedEntity
  ): Promise<DetailedEntity>

  /* Public methods and observables for callers */

  @computed get selectedEntity(): DetailedEntity | undefined {
    const entity =
      this.selectedEntityId && this.detailedEntities[this.selectedEntityId]
    return entity ? this._omitLocalState(entity) : undefined
  }

  @computed get isSelectedDirty(): boolean {
    const entity = this.selectedEntityId
      ? this.detailedEntities[this.selectedEntityId]
      : undefined
    return !!entity?._localState
  }

  /** Returns a mapping from entity id to errors from the server. */
  @computed get entityErrors(): Record<IdType, SyncErrors> {
    const emptyErrors: Record<IdType, SyncErrors> = {}
    return Object.entries(this.detailedEntities).reduce(
      (errors, [entityId, entity]) => {
        if (entity._syncErrors) {
          errors[entityId] = entity._syncErrors
        }
        return errors
      },
      emptyErrors
    )
  }

  @action.bound async refreshAll(): Promise<Entity[]> {
    if (this._currentRefresh) {
      return this._currentRefresh
    }
    // Keep track of the current syncing promise.
    this._currentRefresh = this._syncWithRetries(() => this.refreshEntityList())
    const newEntities = await this._currentRefresh
    this._currentRefresh = undefined

    runInAction(() => {
      // Create temporary maps of created, dirty, and deleted entities.
      const changedEntities: Record<LocalState, Record<IdType, Entity>> = {
        [LocalState.CREATED]: {},
        [LocalState.DIRTY]: {},
        [LocalState.DELETED]: {},
      }
      this.entityList.forEach((entity) => {
        if (entity._localState) {
          changedEntities[entity._localState][entity.id] = entity
        }
      })

      // Merge server list with locally changed copies.
      this.entityList = newEntities.reduce(
        (entities, serverEntity) => {
          // Keep the local copy if it's dirty (or deleted).
          if (serverEntity.id in changedEntities[LocalState.DIRTY]) {
            entities.push(changedEntities[LocalState.DIRTY][serverEntity.id])
          } else if (serverEntity.id in changedEntities[LocalState.DELETED]) {
            entities.push(changedEntities[LocalState.DELETED][serverEntity.id])
          } else {
            // Add the server copy if it wasn't changed locally.
            entities.push(serverEntity)
          }
          return entities
        },
        // Prepend with locally created entities.
        Object.values(changedEntities[LocalState.CREATED])
      )
      this.loadingEntities = false
    })
    return newEntities
  }

  /** Adds a new entity to the store */
  @action.bound add(entity: DetailedEntity): void {
    const cachedEntity = {
      _localState: LocalState.CREATED,
      ...entity,
      _syncErrors: undefined, // clear any previous errors if any
      _lastAccessed: Date.now(),
    }
    this.entityList.push(cachedEntity)
    this.detailedEntities[cachedEntity.id] = cachedEntity
  }

  /** Fetches an entity from the server and updates the store. */
  @action.bound async refresh(entityId?: Entity['id']): Promise<void> {
    this.selectedEntityId = entityId

    if (entityId) {
      const existingEntity = this.detailedEntities[entityId]
      if (existingEntity?._localState) {
        switch (existingEntity._localState) {
          case LocalState.DIRTY:
          case LocalState.DELETED:
            // Keep the local copy.
            return

          case LocalState.CREATED:
            // Should not happen - it means that something got out of sync.
            break
        }
      }

      const details = await this.getEntity(entityId)
      runInAction(() => {
        this.detailedEntities[entityId] = {
          ...details,
          _lastAccessed: Date.now(),
        }
      })
    }
  }

  @action.bound update(entity: DetailedEntity): void {
    const currentLocalState = this.detailedEntities[entity.id]?._localState
    const updatedEntity = {
      // retain CREATED status if not yet synced to server
      _localState:
        currentLocalState == LocalState.CREATED
          ? LocalState.CREATED
          : LocalState.DIRTY,
      ...entity,
      _syncErrors: undefined, // clear any previous errors if any
    }
    this.detailedEntities[entity.id] = updatedEntity
    // Update the entity in the entity list
    this.entityList = this.entityList.map((e) =>
      e.id === entity.id ? updatedEntity : e
    )
  }

  @action.bound remove(entityId: Entity['id']): void {
    this.detailedEntities[entityId] = {
      _localState: LocalState.DELETED,
      ...this.detailedEntities[entityId],
      _syncErrors: undefined, // clear any previous errors if any
    }
    this.entityList = this.entityList.filter((e) => e.id !== entityId)
  }

  startPolling(): void {
    if (!this.pollConfig) {
      throw Error('No poll data configured')
    }
    this.pollService.startPolling(
      () => this.refreshAll(),
      this.pollConfig.name,
      this.pollConfig.interval
    )
  }

  @action.bound stopPolling(): void {
    if (!this.pollConfig) {
      throw Error('No poll data configured')
    }
    this.pollService.stopPolling(this.pollConfig.name)
    this.loadingEntities = true
  }

  /** Private methods */

  _entityActions: Record<
    LocalState,
    (entity: DetailedEntity) => Promise<DetailedEntity>
  > = {
    [LocalState.CREATED]: this.createEntity,
    [LocalState.DIRTY]: this.updateEntity,
    [LocalState.DELETED]: this.deleteEntity,
  }

  _currentRefresh?: Promise<Entity[]>
  _currentSync?: Promise<DetailedEntity | undefined>

  /** Returns an entity without the localState. */
  _omitLocalState(entity: Cacheable<DetailedEntity>): DetailedEntity {
    return omit(entity, [
      '_localState',
      '_syncErrors',
      '_lastAccessed',
    ]) as DetailedEntity
  }

  @action.bound _purgeLru(): void {
    Object.entries(this.detailedEntities).forEach(([key, value]) => {
      // If no time last accessed (legacy) or past the expiry, purge it.
      if (
        !value._lastAccessed ||
        Date.now() - EXPIRATION_MS >= value._lastAccessed
      ) {
        delete this.detailedEntities[key]
      }
    })
  }

  /** Returns an entity that has been changed locally, if any. */
  @computed get _nextDirtyEntity(): Cacheable<DetailedEntity> | undefined {
    return Object.values(this.detailedEntities).find(
      (entity) =>
        entity._localState !== undefined && entity._syncErrors === undefined
    )
  }

  /** Saves errors on the entity object. */
  _augmentWithError(
    entity: DetailedEntity,
    e: AxiosError
  ): Cacheable<DetailedEntity> {
    return {
      ...entity,
      _syncErrors: e.response?.data ?? {
        error: `Unknown error: ${e.response?.status}`,
      },
      _lastAccessed: Date.now(),
    }
  }

  async _syncWithRetries<T>(
    sync: () => Promise<T>,
    timeout = 5000
  ): Promise<T> {
    try {
      const result = await sync()
      ViewStore.setIsOnline(true)
      return result
    } catch (e) {
      if (e instanceof Error && e.message !== 'Network Error') {
        throw e
      }

      // For network errors, recursively retry with exponential backoff, up to 1 minute.
      ViewStore.setIsOnline(false)
      await delay(timeout)
      const newTimeout = Math.min(60 * 1000, timeout * 2)
      return await this._syncWithRetries(sync, newTimeout)
    }
  }

  /**
   * Attempts to sync any locally changed entities to the server.
   * We sync each entity linearly. We can also do this with a Promise.all() but
   * it'd take up more bandwidth, and be trickier to deal with race conditions.
   */
  async _syncLocalEntities(): Promise<void> {
    // Don't sync if there are no changed entities, or we are in the middle of a sync
    const entity = this._nextDirtyEntity
    if (!entity || this._currentSync) {
      return
    }

    const sync = (): Promise<DetailedEntity | undefined> => {
      // Refresh entity, in case it's updated since our last attempt.
      const latestEntity = this.detailedEntities[entity.id]
      const localState = latestEntity?._localState
      if (!localState) {
        // Should not happen - it means that something got out of sync.
        console.warn('Invalid local state for dirty entity', entity)
        return Promise.resolve(undefined)
      }

      return this._entityActions[localState](this._omitLocalState(latestEntity))
    }

    // Keep track of the current syncing promise.
    this._currentSync = this._syncWithRetries(sync)
    let newEntity: Cacheable<DetailedEntity> | undefined
    try {
      newEntity = await this._currentSync
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data) {
        toast.error(`Failed to save: ${JSON.stringify(e.response.data)}`)
      } else {
        toast.error(`Failed to save ${this.constructor.name} ${entity.id}!`)
      }

      if (axios.isAxiosError(e) && e.response?.status !== 400) {
        console.warn('Failed to sync entity with error', entity, e)
      }
      newEntity = this._augmentWithError(
        this.detailedEntities[entity.id],
        e as AxiosError
      )
    } finally {
      this._currentSync = undefined
    }

    runInAction(() => {
      if (!newEntity) {
        newEntity = this.detailedEntities[entity.id]
        if (
          newEntity?._localState === LocalState.DELETED &&
          !newEntity._syncErrors
        ) {
          delete this.detailedEntities[entity.id]
        }
        return
      }
      // Ensure all fields are set correctly
      if (newEntity.directDeliverySiteId === undefined) {
        newEntity.directDeliverySiteId = entity.directDeliverySiteId
      }
      // Ensure deliverySites array is set correctly
      if (newEntity.deliverySites === undefined) {
        newEntity.deliverySites = entity.deliverySites
      } else {
        newEntity.deliverySites = newEntity.deliverySites.map((site, index) => {
          const originalSite = entity.deliverySites
            ? entity.deliverySites[index]
            : undefined
          return {
            ...site,
            dropPointId: site.dropPointId ?? originalSite?.dropPointId,
            estimatedTimeToDelivery:
              site.estimatedTimeToDelivery ??
              originalSite?.estimatedTimeToDelivery,
            id: site.id ?? originalSite?.id,
            isGrounded: site.isGrounded ?? originalSite?.isGrounded,
            lastUpdated: site.lastUpdated ?? originalSite?.lastUpdated,
            name: site.name ?? originalSite?.name,
            status: site.status ?? originalSite?.status,
          }
        })
      }

      // Refresh the entity; this will automatically clear the localState.
      // This will also recursively trigger a reaction to try syncing the next
      // dirty entity, if any.
      this.entityList = this.entityList.map((e) =>
        e.id === entity.id ? newEntity! : e
      )
      if (entity.id !== newEntity.id) {
        // Note that the ID may have updated if it was CREATED.
        delete this.detailedEntities[entity.id]
        if (this.selectedEntityId === entity.id) {
          this.selectedEntityId = newEntity.id
        }
      }
      this.detailedEntities[newEntity.id] = newEntity
    })
  }
}
