import { ComponentType, lazy } from 'react'

import { Router } from 'director/build/director'
import capitalize from 'lodash/capitalize'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { action, autorun, computed, makeObservable, observable } from 'mobx'

import AuthStore from './AuthStore'
import * as MultiNest from './MultiNestStore'

// view components
const AdminLinksView = lazy(() => import('src/views/AdminView/AdminLinksView'))
// Auth pages
const LoginView = lazy(() => import('src/views/LoginView/LoginView'))
const OauthCallback = lazy(() => import('src/views/LoginView/OauthCallback'))
// order processing flow
const CustomerServiceView = lazy(() => import('src/views/CustomerServiceView'))
const PackagingView = lazy(() => import('src/views/PackagingView'))
// inventory management
const AddProductsView = lazy(() => import('src/views/AddProductsView'))
const AddUnitsView = lazy(() => import('src/views/AddUnitsView'))
const DailySummaryView = lazy(() => import('src/views/DailySummaryView'))
const UnitsView = lazy(() => import('src/views/UnitsView'))
const UnitDetailView = lazy(() => import('src/views/UnitDetailView'))
const UnitDigestView = lazy(() => import('src/views/UnitDigestView'))
const InventoryView = lazy(() => import('src/views/InventoryView'))
const ProductDetailView = lazy(() => import('src/views/ProductDetailView'))
const ProductAuditView = lazy(() => import('src/views/ProductAuditView'))
const ProductLimitsView = lazy(() => import('src/views/ProductLimitsView'))
const ReturnUnitsView = lazy(() => import('src/views/ReturnUnitsView'))
const UsersView = lazy(() => import('src/views/UsersView'))
const UpdateUnitSkuView = lazy(() => import('src/views/UnitSkuEditView'))
const ExpiryReportView = lazy(() => import('src/views/ExpiryReportView'))
// delivery site management
const SitesView = lazy(() => import('src/views/SitesView'))
// config
const NotificationsView = lazy(
  () => import('src/views/AdminView/NotificationsView')
)
const RegeneratePodsView = lazy(
  () => import('src/views/RegeneratePodsView/RegeneratePodsView')
)
const SupplierOrderIdEditView = lazy(
  () => import('src/views/SupplierOrderIdEditView')
)
const OffNominalView = lazy(() => import('src/views/OffNominalView'))
const NestSettingsView = lazy(() => import('src/views/NestSettingsView'))

export enum ViewId {
  ADMIN_LINKS = 'Admin',
  // auth
  LOGIN = 'Login',
  OAUTH_CALLBACK = 'Oauth Callback',
  // order processing flow
  CUSTOMER_SERVICE = 'Customer Service',
  PACKAGING = 'Packaging',
  SUMMARY_AND_REPORTS = 'Summary & Reports',
  REGENERATE_PODS = 'Regenerate PODs',
  // inventory management
  ADD_PRODUCTS = 'Add Products',
  PRODUCT_DETAIL = 'Product Detail',
  ADD_UNITS = 'Add Units',
  RETURN_UNITS = 'Return Units',
  UNITS = 'Unit Status',
  UNIT_DETAIL = 'Unit Detail',
  UNIT_DIGEST = 'Unit Digest',
  UNIT_SKU = 'Edit Products for Units',
  INVENTORY = 'Inventory',
  AUDIT = 'Audit',
  SUPPLIER_ORDER_ID = 'Edit Supplier Order ID',
  PRODUCT_LIMITS = 'Facility Product Limits',
  EXPIRY_REPORT = 'Expiry Report',
  // delivery site management
  SITES = 'Delivery Sites',
  // config
  NOTIFICATIONS = 'Notifications',
  // other
  OFF_NOMINAL = 'Off-Nominals',
  USERS = 'Users',
  NEST_SETTINGS = 'Nest Settings',

  // multi nest dropdown
  RW1 = 'RW1',
  RW2 = 'RW2',
  GH1 = 'GH1',
  GH2 = 'GH2',
  GH3 = 'GH3',
  GH4 = 'GH4',
  GH5 = 'GH5',
  GH6 = 'GH6',
  KE1 = 'KE1',
  CI1 = 'CI1',
  NGCR1 = 'NGCR1',
  NGBY1 = 'NGBY1',
  NGKD1 = 'NGKD1',
  UA1 = 'UA1',
  UA2 = 'UA2',
  UA3 = 'UA3',
}

export type ViewData = { [key: string]: string | number | ViewData }

export type QueryParams = Record<string, string>

interface View {
  id: ViewId
  component: () => ComponentType<any>
  flushPadding?: boolean
  data?: ViewData
  params?: QueryParams
}

function createView(viewId: ViewId, component: () => ComponentType<any>) {
  return {
    id: viewId,
    component,
  }
}

export const Views: Record<ViewId, View> = {
  [ViewId.LOGIN]: createView(ViewId.LOGIN, () => LoginView),
  [ViewId.OAUTH_CALLBACK]: createView(
    ViewId.OAUTH_CALLBACK,
    () => OauthCallback
  ),
  [ViewId.ADMIN_LINKS]: createView(ViewId.ADMIN_LINKS, () => AdminLinksView),
  // order processing flow
  [ViewId.CUSTOMER_SERVICE]: createView(
    ViewId.CUSTOMER_SERVICE,
    () => CustomerServiceView
  ),
  [ViewId.PACKAGING]: createView(ViewId.PACKAGING, () => PackagingView),
  [ViewId.SUMMARY_AND_REPORTS]: createView(
    ViewId.SUMMARY_AND_REPORTS,
    () => DailySummaryView
  ),
  [ViewId.REGENERATE_PODS]: createView(
    ViewId.REGENERATE_PODS,
    () => RegeneratePodsView
  ),
  // inventory management
  [ViewId.ADD_PRODUCTS]: createView(ViewId.ADD_PRODUCTS, () => AddProductsView),
  [ViewId.PRODUCT_DETAIL]: createView(
    ViewId.PRODUCT_DETAIL,
    () => ProductDetailView
  ),
  [ViewId.ADD_UNITS]: createView(ViewId.ADD_UNITS, () => AddUnitsView),
  [ViewId.UNITS]: createView(ViewId.UNITS, () => UnitsView),
  [ViewId.UNIT_DETAIL]: createView(ViewId.UNIT_DETAIL, () => UnitDetailView),
  [ViewId.UNIT_DIGEST]: createView(ViewId.UNIT_DIGEST, () => UnitDigestView),
  [ViewId.INVENTORY]: createView(ViewId.INVENTORY, () => InventoryView),
  [ViewId.AUDIT]: createView(ViewId.AUDIT, () => ProductAuditView),
  [ViewId.RETURN_UNITS]: createView(ViewId.RETURN_UNITS, () => ReturnUnitsView),
  [ViewId.SUPPLIER_ORDER_ID]: createView(
    ViewId.SUPPLIER_ORDER_ID,
    () => SupplierOrderIdEditView
  ),
  [ViewId.USERS]: createView(ViewId.USERS, () => UsersView),
  [ViewId.UNIT_SKU]: createView(ViewId.UNIT_SKU, () => UpdateUnitSkuView),
  [ViewId.EXPIRY_REPORT]: createView(
    ViewId.EXPIRY_REPORT,
    () => ExpiryReportView
  ),
  // delivery site management
  [ViewId.SITES]: createView(ViewId.SITES, () => SitesView),
  // config
  [ViewId.NOTIFICATIONS]: createView(
    ViewId.NOTIFICATIONS,
    () => NotificationsView
  ),
  // other
  [ViewId.OFF_NOMINAL]: createView(ViewId.OFF_NOMINAL, () => OffNominalView),
  [ViewId.PRODUCT_LIMITS]: createView(
    ViewId.PRODUCT_LIMITS,
    () => ProductLimitsView
  ),
  [ViewId.NEST_SETTINGS]: createView(
    ViewId.NEST_SETTINGS,
    () => NestSettingsView
  ),
  // multi nest dropdown
  [ViewId.RW1]: createView(ViewId.RW1, () => MultiNest.Rw1Linkview),
  [ViewId.RW2]: createView(ViewId.RW2, () => MultiNest.Rw2LinkView),
  [ViewId.GH1]: createView(ViewId.GH1, () => MultiNest.Gh1LinkView),
  [ViewId.GH2]: createView(ViewId.GH2, () => MultiNest.Gh2LinkView),
  [ViewId.GH3]: createView(ViewId.GH3, () => MultiNest.Gh3LinkView),
  [ViewId.GH4]: createView(ViewId.GH4, () => MultiNest.Gh4LinkView),
  [ViewId.GH5]: createView(ViewId.GH5, () => MultiNest.Gh5LinkView),
  [ViewId.GH6]: createView(ViewId.GH6, () => MultiNest.Gh6LinkView),
  [ViewId.KE1]: createView(ViewId.KE1, () => MultiNest.Ke1LinkView),
  [ViewId.CI1]: createView(ViewId.CI1, () => MultiNest.Ci1LinkView),
  [ViewId.NGCR1]: createView(ViewId.NGCR1, () => MultiNest.Ngcr1LinkView),
  [ViewId.NGBY1]: createView(ViewId.NGBY1, () => MultiNest.Ngby1LinkView),
  [ViewId.NGKD1]: createView(ViewId.NGKD1, () => MultiNest.Ngkd1LinkView),
  [ViewId.UA1]: createView(ViewId.UA1, () => MultiNest.Ua1LinkView),
  [ViewId.UA2]: createView(ViewId.UA2, () => MultiNest.Ua2LinkView),
  [ViewId.UA3]: createView(ViewId.UA3, () => MultiNest.Ua3LinkView),
}

class ViewStore {
  @observable currentView: View = AuthStore.currentUser
    ? Views[ViewId.CUSTOMER_SERVICE]
    : Views[ViewId.LOGIN]
  @observable previousView?: View = undefined
  @observable isOnline = true
  initialized = false

  onLeave?: {
    message: string
    callback?: () => void
  }

  constructor() {
    makeObservable(this)
    this.startRouter()
  }

  @computed get currentPath(): string {
    switch (this.currentView.id) {
      case ViewId.LOGIN:
        return '/v3/login'
      case ViewId.OAUTH_CALLBACK:
        return '/v3/callback'
      case ViewId.ADMIN_LINKS:
        return '/v3/admin'
      case ViewId.NOTIFICATIONS:
        return '/v3/admin/notifications'
      case ViewId.CUSTOMER_SERVICE:
        return `/v3/orders/${this.currentView?.data?.orderId ?? ''}`
      case ViewId.PACKAGING:
        return '/v3/packaging'
      case ViewId.SUMMARY_AND_REPORTS:
        return '/v3/summary'
      case ViewId.REGENERATE_PODS:
        return '/v3/regenerate_pods'
      case ViewId.SUPPLIER_ORDER_ID:
        return '/v3/edit_supplier_order_id'
      case ViewId.ADD_PRODUCTS:
        return '/v3/add_products'
      case ViewId.PRODUCT_DETAIL:
        return `/v3/products/${this.currentView?.data?.sku ?? ''}`
      case ViewId.ADD_UNITS:
        return '/v3/add_units'
      case ViewId.RETURN_UNITS:
        return '/v3/return_units'
      case ViewId.USERS:
        if (
          this.currentView.data &&
          this.currentView.data.userType &&
          this.currentView.data.userId
        ) {
          return `/v3/users/${this.currentView.data.userType}/${this.currentView.data.userId}`
        } else if (this.currentView.data && this.currentView.data.userType) {
          return `/v3/users/${this.currentView.data.userType}`
        }
        return '/v3/users'
      case ViewId.UNIT_SKU:
        return '/v3/update_unit_sku'
      case ViewId.SITES:
        if (
          this.currentView.data &&
          this.currentView.data.siteType &&
          this.currentView.data.siteId
        ) {
          return `/v3/sites/${this.currentView.data.siteType}/${this.currentView.data.siteId}`
        } else if (this.currentView.data && this.currentView.data.siteType) {
          return `/v3/sites/${this.currentView.data.siteType}`
        }
        return '/v3/sites'
      case ViewId.UNITS:
        return '/v3/units'
      case ViewId.UNIT_DETAIL:
        return `/v3/units/${this.currentView?.data?.unitId ?? ''}`
      case ViewId.UNIT_DIGEST:
        return `/v3/unitdigest/${this.currentView?.data?.productGroup ?? ''}`
      case ViewId.INVENTORY:
        return '/v3/inventory'
      case ViewId.AUDIT:
        return '/v3/audit'
      case ViewId.EXPIRY_REPORT:
        return '/v3/expiry_report'
      case ViewId.OFF_NOMINAL:
        return '/v3/off_nominals'
      case ViewId.PRODUCT_LIMITS:
        return '/v3/product_limits'
      case ViewId.NEST_SETTINGS:
        return '/v3/nest_settings'
      default:
        return '/v3/orders'
    }
  }

  @action.bound setIsOnline(value: boolean) {
    this.isOnline = value
  }

  @action setOnLeaveConfirmation(
    message?: string,
    callback?: () => void
  ): void {
    this.onLeave = message ? { message, callback } : undefined
    // Add an event listener in case the user tries to leave the web page.
    // TODO(ivan.wang): Sadly Chrome does not actually support this message :(
    // Also, the callback is always called, even if the user stays.
    // Implement a better alert system with our own message.
    window.onbeforeunload = message
      ? () => {
          callback && callback()
          return message
        }
      : null
  }

  /**
   * Triggers the on-leave confirmation and returns whether the user confirmed.
   */
  @action triggerOnLeaveConfirmation(): boolean {
    if (!this.onLeave) {
      return true
    } else if (confirm(this.onLeave.message)) {
      this.onLeave.callback && this.onLeave.callback()
      this.onLeave = undefined
      window.onbeforeunload = null
      return true
    }

    return false
  }

  @action showView(
    viewId: ViewId,
    data: ViewData = {},
    params?: QueryParams
  ): void {
    if (this.triggerOnLeaveConfirmation()) {
      if (this.initialized) {
        this.previousView = this.currentView
      } else {
        this.initialized = true
      }

      // If only the data is changing, then only update the data
      if (this.currentView.id === viewId) {
        this.currentView.data = data
        this.currentView.params = params
      } else {
        this.currentView = { ...Views[viewId], data, params }
      }
    }
  }

  setQueryParams(params: QueryParams): void {
    const base = window.location.href.split('?')[0]
    const urlParams = this.queryParamsToUrl(params)
    const url = isEmpty(urlParams) ? base : `${base}?${urlParams}`

    window.history.replaceState(undefined, '', url)
  }

  get queryParams(): QueryParams {
    const urlParams = new URLSearchParams(window.location.search)
    const params: QueryParams = {}
    urlParams.forEach((value, key) => (params[key] = value))
    return params
  }

  getQueryParams(key: string, defaultValue = ''): string {
    return this.queryParams?.[key] ?? defaultValue
  }

  queryParamsToUrl(params: QueryParams): string {
    const urlParams = new URLSearchParams(window.location.search)
    const urlParamsToKeep = new URLSearchParams()
    // if a param doesn't exist anymore, clear it
    urlParams.forEach((value, key) => {
      if (key in params) {
        urlParamsToKeep.set(key, value)
      }
    })

    // update params as necessary
    forEach(params, (value, key) => urlParamsToKeep.set(key, value))

    return urlParamsToKeep.toString()
  }

  startRouter(): void {
    const ROUTES = {
      '/v3': () => this.showView(ViewId.CUSTOMER_SERVICE),
      '/v3/login': () => this.showView(ViewId.LOGIN),
      '/v3/callback': () => this.showView(ViewId.OAUTH_CALLBACK),
      '/v3/admin': () => this.showView(ViewId.ADMIN_LINKS),
      '/v3/admin/notifications': () => this.showView(ViewId.NOTIFICATIONS),
      '/v3/orders/:orderId': (orderId: string) =>
        this.showView(ViewId.CUSTOMER_SERVICE, { orderId }),
      '/v3/packaging': () => this.showView(ViewId.PACKAGING),
      '/v3/summary': () => this.showView(ViewId.SUMMARY_AND_REPORTS),
      '/v3/add_products': () => this.showView(ViewId.ADD_PRODUCTS),
      '/v3/regenerate_pods': () => this.showView(ViewId.REGENERATE_PODS),
      '/v3/edit_supplier_order_id': () =>
        this.showView(ViewId.SUPPLIER_ORDER_ID),
      '/v3/products/:sku': (sku: string) =>
        this.showView(ViewId.PRODUCT_DETAIL, { sku }),
      '/v3/add_units': () => this.showView(ViewId.ADD_UNITS),
      '/v3/return_units': () => this.showView(ViewId.RETURN_UNITS),
      '/v3/users': () => this.showView(ViewId.USERS),
      '/v3/users/:userType': (userType: string) =>
        this.showView(ViewId.USERS, { userType }),
      '/v3/users/:userType/new': (userType: string) =>
        this.showView(ViewId.USERS, { userType }),
      '/v3/users/:userType/:userId': (userType: string, userId: string) =>
        this.showView(ViewId.USERS, { userType, userId }),
      '/v3/update_unit_sku': () => this.showView(ViewId.UNIT_SKU),
      '/v3/sites': () => this.showView(ViewId.SITES),
      '/v3/sites/:siteType': (siteType: string) =>
        this.showView(ViewId.SITES, { siteType }),
      '/v3/sites/:siteType/new': (siteType: string) =>
        this.showView(ViewId.SITES, { siteType }),
      '/v3/sites/:siteType/:siteId': (siteType: string, siteId: string) =>
        this.showView(ViewId.SITES, { siteType, siteId }),
      '/v3/units': () => this.showView(ViewId.UNITS),
      '/v3/units/:unitId': (unitId: string) =>
        this.showView(ViewId.UNIT_DETAIL, { unitId }),
      '/v3/unitdigest/:productGroup': (productGroup: string) =>
        this.showView(ViewId.UNIT_DIGEST, {
          productGroup: capitalize(productGroup),
        }),
      '/v3/inventory': () => this.showView(ViewId.INVENTORY),
      '/v3/audit': () => this.showView(ViewId.AUDIT),
      '/v3/expiry_report': () => this.showView(ViewId.EXPIRY_REPORT),
      '/v3/off_nominals': () => this.showView(ViewId.OFF_NOMINAL),
      '/v3/product_limits': () => this.showView(ViewId.PRODUCT_LIMITS),
      '/v3/nest_settings': () => this.showView(ViewId.NEST_SETTINGS),
    }

    const router = new Router(ROUTES)
    router.configure({
      notfound: () => this.showView(ViewId.CUSTOMER_SERVICE),
      html5history: true,
    })
    router.init()

    autorun(() => {
      const path = this.currentPath
      const queryParams = this.currentView.params
        ? `?${this.queryParamsToUrl(this.currentView.params)}`
        : // Preserve query params if first load, or same view id
          !this.previousView || this.previousView.id === this.currentView.id
          ? window.location.search
          : ''
      if (
        path !== window.location.pathname ||
        queryParams !== window.location.search
      ) {
        window.history.pushState(null, '', path.split('?')[0] + queryParams)
      }
    })
  }
}

export default new ViewStore()
